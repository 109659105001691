import { Box, Stack, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import { formatInTimeZone } from 'date-fns-tz';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { GreenButton, VioletButton } from '../../components/styled-components';
import { TicketDetails } from '../../modules/tickets/models';
import { getTicketDetails } from '../../modules/tickets/operations';
import AnswerEditor from './AnswerEditor';
import Grid from '@mui/material/Unstable_Grid2';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../utils/hooks';

export const HeaderText = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    fontSize: '1.125rem',
  },

  fontWeight: 700,
  fontSize: '1.9rem',
  color: '#2B2A2A',
  fontFamily: 'Poppins',
  textAlign: 'center',
}));
export const HeaderColumnText = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    fontSize: '1.125rem',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '1rem',
  },
  fontWeight: 400,
  fontSize: '1.5rem',
  color: '#2B2A2A',
  fontFamily: 'Poppins',
}));
export const ValueColumnText = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    fontSize: '1.125rem',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '1rem',
  },
  fontWeight: 700,
  fontSize: '1.5rem',
  color: '#2B2A2A',
  fontFamily: 'Poppins',
}));

export const getFormattedDate = (dateStr: string | undefined): string => {
  if (dateStr) {
    const date = new Date(dateStr);
    return formatInTimeZone(date, 'Europe/Warsaw', 'dd.MM.yyyy HH:mm');
  }
  return '';
};

export const getFormattedDateSince = (dateStr: string | undefined): string => {
  if (dateStr) {
    const date = new Date(dateStr);
    date.setHours(date.getHours() - 1);
    return formatInTimeZone(date, 'Europe/Warsaw', 'dd.MM.yyyy HH:mm');
  }
  return '';
};

export const getFormattedDateUntil = (dateStr: string | undefined): string => {
  if (dateStr) {
    const date = new Date(dateStr);
    date.setHours(date.getHours() + 23);
    return formatInTimeZone(date, 'Europe/Warsaw', 'dd.MM.yyyy HH:mm');
  }
  return '';
};

const ValidTicket = () => {
  const { t } = useTranslation('common');
  const { id } = useParams();
  const navigate = useNavigate();
  const [ticket, setTicket] = useState<TicketDetails | null>(null);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const theme = useTheme();
  const isMdScreen = useMediaQuery(theme.breakpoints.down('md'));
  const dispatch = useAppDispatch();

  useEffect(() => {
    const GetTicket = async () => {
      if (id) {
        const td = await dispatch(getTicketDetails(id)).unwrap();
        console.log(td);
        setTicket(td);
      }
    };
    GetTicket();
  }, [id]);

  return (
    <>
      <Grid container sx={{ pt: 2 }}>
        <Grid lgOffset={3} lg={6} xs={12} sx={{ backgroundColor: 'white', px: isMdScreen ? 2 : 6, pb: 2 }}>
          <Stack
            direction="column"
            sx={{ width: '100%', alignItems: 'center', mt: isMdScreen ? 0 : 4 }}
            spacing={{ xs: 3, md: 3, lg: 4 }}
          >
            <HeaderText>
              <>{t('ticketStatus.validTicketHeader')}</>
            </HeaderText>
            <Box component="img" src="/images/valid.png" sx={{ height: '20vh' }}></Box>
            <Box sx={{ alignItems: 'left' }}>
              <Stack direction="row" spacing={2}>
                <HeaderColumnText>
                  <>{t('ticketStatus.ticketIdHeader')}</>:
                </HeaderColumnText>
                <ValueColumnText>{ticket?.ticketId}</ValueColumnText>
              </Stack>
              {ticket?.question != null && (
                <Stack direction="row" spacing={2}>
                  <HeaderColumnText>{ticket?.question?.question}:</HeaderColumnText>
                  <ValueColumnText>{ticket?.question?.answer}</ValueColumnText>
                </Stack>
              )}
              <Stack direction="row" spacing={2}>
                <HeaderColumnText>
                  <>{t('ticketStatus.ticketTypeHeader')}</>:
                </HeaderColumnText>
                <ValueColumnText>{ticket?.variantName}</ValueColumnText>
              </Stack>
              <Stack direction="row" spacing={2}>
                <HeaderColumnText>
                  <>{t('ticketStatus.validSinceHeader')}</>:
                </HeaderColumnText>
                <ValueColumnText>{getFormattedDateSince(ticket?.validSince)}</ValueColumnText>
              </Stack>
              <Stack direction="row" spacing={2}>
                <HeaderColumnText>
                  <>{t('ticketStatus.validUntilHeader')}</>:
                </HeaderColumnText>
                <ValueColumnText>{getFormattedDateUntil(ticket?.validUntil)}</ValueColumnText>
              </Stack>
              <Stack direction="row" spacing={2}>
                <HeaderColumnText>
                  <>{t('ticketStatus.ticketPriceHeder')}</>:
                </HeaderColumnText>
                <ValueColumnText>
                  {ticket?.price} EUR
                </ValueColumnText>
              </Stack>
            </Box>
            <Stack direction={{ xs: 'row' }} spacing={1}>
              <GreenButton onClick={() => navigate('/')}>
                <>{t('buttons.complete')}</>
              </GreenButton>
              {ticket?.question != null && (
                <VioletButton onClick={() => setOpenDialog(true)}>
                  <>{t('ticketStatus.changeAnswer')}</>
                </VioletButton>
              )}
            </Stack>
          </Stack>
        </Grid>
      </Grid>
      <AnswerEditor handleClose={() => setOpenDialog(false)} open={openDialog} ticket={ticket}></AnswerEditor>
    </>
  );
};

export default ValidTicket;
