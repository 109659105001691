import {
  GlobalStyles,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useAppDispatch } from '../../utils/hooks';
import React, { useEffect, useState } from 'react';
import { fetchParkingTickets } from '../../modules/parkingTickets/operations';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ReturnButton } from '../../components/styled-components';
import { useTranslation } from 'react-i18next';

const SearchResult = () => {
  const { t } = useTranslation(['common', 'pages']);
  const [searchParams] = useSearchParams();
  const [plateInput, setPlateInput] = useState<string>('');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const driverHasFines = Math.random() < 0.5;

  const getRandomFutureDate = (): String => {
    const today = new Date();
    const randomDays = Math.floor(Math.random() * 30) + 1;
    const futureDate = new Date(today);
    futureDate.setDate(today.getDate() + randomDays);
    return futureDate.toISOString().split('T')[0];
  };

  useEffect(() => {
    if (plateInput) {
      dispatch(fetchParkingTickets(plateInput));
    }
  }, [plateInput]);

  useEffect(() => {
    const plate = searchParams.get('search');
    if (plate) {
      setPlateInput(plate);
    }
  }, []);

  return (
    <>
      <GlobalStyles
        styles={{
          body: { backgroundColor: '#4398D1' },
          html: { backgroundColor: '#4398D1' },
        }}
      />
      <Stack direction="column" spacing={6} sx={{ py: 6, backgroundColor: '#4398D1' }}>
        <Stack direction={'row'} justifyContent={'space-between'}>
          <ReturnButton onClick={() => navigate('/driver_check')}>
            <>{t('common:buttons.back')}</>
          </ReturnButton>
        </Stack>

        <Typography sx={{ fontSize: '1.875rem', lineHeight: '3.2rem', color: 'white', px: 2, textAlign: 'center' }}>
          {t('common:driverCheck.driversValidation')}
        </Typography>
        {driverHasFines && (
          <Typography sx={{
            fontSize: '1.875rem',
            lineHeight: '3.2rem',
            backgroundColor: '#FF4242',
            color: 'white',
            px: 2,
            textAlign: 'center',
          }}>
            {t('common:driverCheck.driversValidationHasFines').toString()}
          </Typography>
        )}
        {!driverHasFines && (
          <Typography sx={{
            fontSize: '1.875rem',
            lineHeight: '3.2rem',
            backgroundColor: '#32BA7C',
            color: 'white',
            px: 2,
            textAlign: 'center',
          }}>
            {t('common:driverCheck.driversValidationNoFines').toString()}
          </Typography>
        )}
        {driverHasFines && (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align={'center'} sx={{ fontWeight: 'bold' }}>Violation</TableCell>
                  <TableCell align={'center'} sx={{ fontWeight: 'bold' }}>Amount</TableCell>
                  <TableCell align={'center'} sx={{ fontWeight: 'bold' }}>Due Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow key="1">
                  <TableCell align={'center'}>Speeding</TableCell>
                  <TableCell align={'center'}>250 PLN</TableCell>
                  <TableCell align={'center'}>{getRandomFutureDate()}</TableCell>
                </TableRow>
                <TableRow key="1">
                  <TableCell align={'center'}>Illegal Parking</TableCell>
                  <TableCell align={'center'}>100 PLN</TableCell>
                  <TableCell align={'center'}>{getRandomFutureDate()}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Stack>
    </>
  );
};

export default SearchResult;
