import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import DriverCheck from '../components/DriverCheckForm';

const PlateSearcher = () => {
  const { t } = useTranslation(['common']);
  const navigate = useNavigate();
  const handleConfirmClick = (peselNumber: string) => {
    navigate(`/driver_check/result?search=${peselNumber}`);
  };
  return (
    <DriverCheck
      handleReturn={() => navigate('/')}
      handleConfirm={handleConfirmClick}
      confirmButtonText={t('common:buttons.search')}
      bgColor={'#4398D1'}
    ></DriverCheck>
  );
};

export default PlateSearcher;
