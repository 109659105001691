import { Alert, Box, Button, GlobalStyles, Snackbar, Stack, styled, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { ReturnButton } from './styled-components';
import Grid from '@mui/material/Unstable_Grid2';
import { useAppSelector } from '../utils/hooks';
import { getEmbeddedLprScanner } from '../modules/layout/selectors';
import { useTranslation } from 'react-i18next';
import { ButtonColor } from '../layout/colors';

export const InputField = styled(TextField)({
  maxHeight: 140,
  variant: 'outlined',
  width: '100%',
  borderColor: 'white',
  '& input': {
    fontSize: '3.75rem',
    color: 'white',
    border: '2px solid white',
    textTransform: 'uppercase',
  },
});

export const SearchButton = styled(Button)({
  backgroundColor: ButtonColor.Green,
  color: 'white',
  '&:hover': {
    backgroundColor: ButtonColor.GreenHover,
  },
  borderRadius: '8px',
  fontWeight: 800,
  fontSize: '2rem',
  maxHeight: '120px',
  height: '120px',
  width: '100%',
});

interface DriverCheckProps {
  handleReturn: () => void;
  handleConfirm: (peselNumber: string) => void;
  confirmButtonText: string;
  bgColor?: string;
  children?: JSX.Element;
}

const DriverCheck = ({ handleReturn, handleConfirm, confirmButtonText, bgColor, children }: DriverCheckProps) => {
  const { t } = useTranslation(['common']);
  const [openError, setoOpenError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [peselNumber, setPeselNumber] = useState<string>('');
  const embeddedLprScanner = useAppSelector((x) => getEmbeddedLprScanner(x.layout));

  const handleSubmit = (e) => {
    e.preventDefault();
  };
  const displayError = (message: string): void => {
    setErrorMessage(message);
    setoOpenError(true);
  };

  const handleSearchClick = () => {
    if (peselNumber.length === 0) {
      displayError(t('common:driverCheck.enterPeselNumberErrorMessage'));
      return;
    }
    if (peselNumber.length !== 11) {
      displayError(t('common:driverCheck.invalidPeselNumberErrorMessage'));
      return;
    }
    handleConfirm(peselNumber);
  };

  const handleOpenCameraOcr = () => {
    if (window.BtDevice) {
      window.BtDevice.startPeselScan();
      window.BtApp.handlePeselScan = (peselNumber: string) => {
        console.log('Zeskanowano PESEL: ' + peselNumber);
        if (peselNumber !== undefined) {
          setPeselNumber(peselNumber);
        }
      };
    }
  };

  return (
    <>
      <GlobalStyles
        styles={{
          body: { backgroundColor: bgColor ? bgColor : '#4398D1' },
          html: { backgroundColor: bgColor ? bgColor : '#4398D1' },
        }}
      />
      <form onSubmit={handleSubmit}>
        <Stack direction="column" spacing={1} sx={{ py: 6, backgroundColor: bgColor ? bgColor : '#4398D1' }}>
          <ReturnButton onClick={handleReturn}>
            <>{t('common:buttons.back')}</>
          </ReturnButton>
          <Typography sx={{ fontSize: '1.875rem', lineHeight: '3.2rem', color: 'white', px: 2, textAlign: 'center' }}>
            {t('common:driverCheck.enterPesel').toString()}
          </Typography>
          <Grid container rowSpacing={2}>
            <Grid xs={12} md={embeddedLprScanner ? 11 : 12}>
              <InputField
                autoFocus={!embeddedLprScanner}
                onChange={(e) => setPeselNumber(e.target.value)}
                value={peselNumber}
              ></InputField>
            </Grid>
            {embeddedLprScanner && (
              <Grid xs={3} md={1} alignSelf="center">
                <Button onClick={handleOpenCameraOcr}>
                  <Box component="img" src="/images/camera_scanner.png" justifyContent="center"></Box>
                </Button>
              </Grid>
            )}
            <Grid xs={embeddedLprScanner ? 9 : 12} md={4} mdOffset={4}>
              <SearchButton onClick={() => handleSearchClick()} sx={{ alignSelf: 'center' }} type="submit">
                {confirmButtonText}
              </SearchButton>
            </Grid>
          </Grid>
        </Stack>
      </form>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={openError}
        onClose={() => setoOpenError(false)}
        autoHideDuration={3000}
      >
        <Alert onClose={() => setoOpenError(false)} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default DriverCheck;
