import { Box, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { PaymentProvider } from '../utils/device';

interface PaymentTypeBoxProps {
  paymentType: PaymentProvider;
}

const PaymentTypeBox = ({ paymentType }: PaymentTypeBoxProps) => {
  const [paymentTypeImg, setPaymentTypeImg] = useState<string>('');

  useEffect(() => {
    setPaymentTypeImg('/images/bergregions.png');
  }, [paymentType]);

  return (
    <Stack direction="row" spacing={1} sx={{ alignItems: 'center', justifyContent: 'center', mt: 3 }}>
      <Typography>Powered by</Typography>
      <Box component="img" src={paymentTypeImg} sx={{ maxWidth: '138px', objectFit: 'contain' }}></Box>
    </Stack>
  );
};

export default PaymentTypeBox;
