import { Box, Divider, Grid, Paper, Stack, Typography } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import { CardMedia } from '@mui/material';
import { getEncodedImage } from '../../utils/device';
import { useTranslation } from 'react-i18next';

interface QrTicketProps {
  qrCode: string;
  name: string;
}

const QrTicket = ({ qrCode, name }: QrTicketProps) => {
  const { t } = useTranslation(['common']);
  return (
    <>
      <Paper sx={{ width: '685px', backgroundColor: 'white', minHeight: '415px' }}>
        <Stack direction="row" alignItems="center">
          <CircleIcon sx={{ fontSize: '15px', mx: 3, my: 3 }} htmlColor="black"></CircleIcon>
          <Typography sx={{ fontSize: '25px' }}>
            <>{t('common:qrCodes.ticket')}</>
          </Typography>
        </Stack>
        <Divider color="#D9D9D9"></Divider>
        <Box sx={{ mx: 3, mt: 1 }} alignContent="center">
          <Typography sx={{ fontSize: '18px' }}>{name}</Typography>
        </Box>
        <Grid container sx={{ mt: 1 }}>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
            <CardMedia component="img" src={getEncodedImage(qrCode)} sx={{ width: '360px', height: '240px' }}></CardMedia>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default QrTicket;
