export const terminalCodeDescriptions = new Map<number, string>([
  [2, 'Transaction Canceled'],
  [33, 'Card Communication Error'],
  [20, 'Waiting for Card Payment Data'],
  [30, 'Checking Card Number Validity'],
  [40, 'Waiting for Merchant Identifier'],
  [50, 'Supervisor Password Verification'],
  [60, 'Waiting for Transaction Amount Entry'],
  [65, 'Terminal is Busy'],
  [70, 'Waiting for Previous Transaction Amount'],
  [80, 'Waiting for Previous Authorization Code'],
  [90, 'Waiting for PIN Code Entry'],
  [100, 'Connecting to Authorization Host'],
  [101, 'Connecting to Authorization Host - Attempt 2'],
  [102, 'Connecting to Authorization Host - Attempt 3'],
  [110, 'Voice Authorization'],
  [120, 'Verifying Customer Signature'],
  [130, 'Verifying Customer Identity'],
  [140, 'Printing Receipt (Positive Authorization)'],
  [155, 'Printing Receipt Copy'],
  [150, 'Printing Receipt (Rejection or Error)'],
  [180, 'Canceling Current Transaction'],
  [190, 'Printing Cancellation Receipt'],
  [1000, 'Asking for Customer Copy'],
  // PayTel LightPOS codes.
  [2001, 'Terminal Not Activated'],
  [2002, 'Terminal Not Found'],
  [2003, 'Transaction Failed'],
  [2004, 'Invalid Callback Provided'],
  [2005, 'Transaction Canceled'],
  [2006, 'UserHash Not Found'],
]);

export enum TerminalState {
  NO_CONNECTION = 'NO_CONNECTION',
  AMOUNT_INVALID = 'AMOUNT_INVALID',
  INTERNAL_ERROR = 'INTERNAL_ERROR',
  PAYMENT_STARTED = 'PAYMENT_STARTED',
  PAYMENT_STARTED_OK = 'OK', // todo remove after value in Android is changed
  repeat = 'repeat',
  cancel = 'cancel',
  exception = 'exception',
}

export enum PaymentState {
  ERROR = 'ERROR',
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
}
