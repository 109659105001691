import { Alert, CircularProgress, Snackbar, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getEmbeddedQrScanner, getEnabledFeatures, getSelfCheckout } from '../modules/layout/selectors';
import { AppFeature } from '../utils/device';
import { useAppSelector, useScannerIndicator } from '../utils/hooks';
import React, { useEffect, useMemo } from 'react';
import ScannerIndicator from '../components/ScannerIndicator/ScannerIndicator';
import useQrReader from '../hooks/useQrReader';
import Item from './modeSelector/Item';
import TicketSellCheckoutItem from './modeSelector/TicketScanSelfCheckoutItem';

const ModeSelector = () => {
  const { t } = useTranslation(['common']);
  const embeddedQrScanner = useAppSelector((x) => getEmbeddedQrScanner(x.layout));
  const enabledFeatures = useAppSelector((s) => getEnabledFeatures(s.layout));
  const selfCheckout = useAppSelector((s) => getSelfCheckout(s.layout));
  const scannerIndicator = useScannerIndicator();
  const [handleQrReader, openLoader, errorMessage, openError, handleOpenError] = useQrReader();

  const ticketsScanSelfCheckoutLabel = useMemo(() => {
    if (selfCheckout) {
      return t('common:modeSelector.ticketScanSelfCheckout');
    } else {
      return t('common:modeSelector.ticketScan');
    }
  }, [selfCheckout, t]);

  useEffect(() => {
    if (window.BtApp) {
      console.log('assign handleQrReader');
      window.BtApp.handleQrReader = handleQrReader;
    }
    return () => {
      if (window.BtApp && window.BtApp.handleQrReader) {
        console.log('Unregister handleQrReader func');
        window.BtApp.handleQrReader = undefined;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [embeddedQrScanner]);

  return (
    <>
      <Stack
        direction={selfCheckout ? 'column-reverse' : 'column'}
        sx={{ height: '100%', minHeight: '100%' }}
        alignItems={'stretch'}
        justifyContent={selfCheckout ? 'flex-end' : 'flex-start'}
        spacing={3}
        py={2}
      >
        {enabledFeatures.includes(AppFeature.TicketSale) ? <TicketSellCheckoutItem /> : null}
        {enabledFeatures.includes(AppFeature.TicketScan) ? (
          <Item
            sx={{ backgroundColor: '#EE4A83', '&:hover': { backgroundColor: '#EE4A83' } }}
            description={ticketsScanSelfCheckoutLabel}
            iconUrl="/images/sel-scan.png"
            pageUrl="/scan"
          ></Item>
        ) : null}
        {enabledFeatures.includes(AppFeature.TicketSearchByCarPlates) ? (
          <Item
            sx={{ backgroundColor: '#FFB039', '&:hover': { backgroundColor: '#FFB039' } }}
            description={t('common:modeSelector.ticketSearchByCarPlates')}
            iconUrl="/images/sel-car.png"
            pageUrl="/plate_search"
          ></Item>
        ) : null}
        {enabledFeatures.includes(AppFeature.DriverCheck) ? (
          <Item
            sx={{ backgroundColor: '#4398D1', '&:hover': { backgroundColor: '#4398D1' } }}
            description={t('common:modeSelector.driverCheck')}
            iconUrl="/images/sel-driver.png"
            pageUrl="/driver_check"
          ></Item>
        ) : null}
        {enabledFeatures.includes(AppFeature.ProductSale) ? (
          <Item
            sx={{ backgroundColor: '#6260A8', '&:hover': { backgroundColor: '#6260A8' } }}
            description={t('common:modeSelector.productSale')}
            iconUrl="/images/sel-product.png"
            pageUrl="/products"
          ></Item>
        ) : null}
      </Stack>
      {scannerIndicator && selfCheckout ? <ScannerIndicator /> : null}
      {openLoader ? (
        <Stack alignItems="center" direction="column" spacing={1}>
          <CircularProgress />
          <Typography align="center">
            <>{t('common:scanner.fetchingData')}</>
          </Typography>
        </Stack>
      ) : null}
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={openError}
        onClose={() => handleOpenError(false)}
        autoHideDuration={3000}
      >
        <Alert onClose={() => handleOpenError(false)} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ModeSelector;
